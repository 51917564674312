import { Navigate, Route, Routes } from "react-router-dom";

import Hq from "./pages/Hq";
import Global from "./pages/Global";
import Layout from "./components/Layout";

const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Hq />} />
        <Route path="/global" element={<Global />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
