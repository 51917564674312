import { Link, NavLink, Outlet } from "react-router-dom";

import logo from "../assets/logo.png";
import { Header } from "../styles";
const navLinks = [
  {
    label: "HQ",
    path: "/",
  },
  {
    label: "Global",
    path: "/global",
  },
];

const Layout = () => {
  return (
    <div className="flex items-center justify-center relative">
      <Header className="absolute w-full top-0 left-0">
        <img src={logo} width={140} />
      </Header>
      <div className="relative w-full max-w-[calc(602px+1.5rem)] border  mx-auto mt-[calc(60px+2rem)] p-3 rounded-sm">
        <ul className="absolute flex -left-[1px] bottom-full text-sm">
          {navLinks.map((link) => (
            <li className="inline-flex">
              <NavLink
                to={link.path}
                className={({ isActive }) =>
                  `px-3 py-2 rounded-t-lg border border-b-[#e5e7eb] bg-white ${
                    isActive
                      ? "border border-b-[transparent] font-semibold"
                      : "border-[transparent]"
                  }`
                }
              >
                {link.label}
              </NavLink>
            </li>
          ))}
        </ul>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
