import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

import { ISignature } from "../interface/Signature";
import SignaturePreview from "./SignaturePreview";

import { SignatureInput, SignatureLabel, SignatureSelect } from "./FormStyles";

export type FormConfig = {
  id: string;
  type: "text" | "email" | "tel" | "select" | "url";
  label: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  options?: { value: string; label: string }[]; // for select
};

export type FormRow = FormConfig[];
export type FormData = { [key: string]: string };

interface Props {
  formConfig: FormRow[];
  onFormSubmit?: (data: FormData) => void;
  onFormChange?: (data: FormData) => void;
}

const FormRow = (config: FormConfig & { onBlur: () => void }) => {
  const { register } = useFormContext();

  if (config.type === "select") {
    return (
      <div className="flex-1">
        <SignatureLabel htmlFor={config.id}>{config.label}</SignatureLabel>
        <SignatureSelect
          {...register(config.id, {
            required: !!config.required,
            onBlur: config.onBlur,
          })}
        >
          <option disabled value="">
            {config.placeholder ?? "please select country code"}
          </option>
          {config.options?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label} ({value})
            </option>
          ))}
        </SignatureSelect>
      </div>
    );
  }

  return (
    <div className="flex-1">
      <SignatureLabel htmlFor={config.id}>{config.label}</SignatureLabel>
      <SignatureInput
        {...register(config.id, {
          required: !!config.required,
          onBlur: config.onBlur,
        })}
        required={!!config.required}
        id={config.id}
        type={config.type}
        placeholder={config.placeholder}
      />
    </div>
  );
};

const SignatureForm = React.forwardRef<HTMLFormElement, Props>(
  ({ formConfig, onFormChange, onFormSubmit }: Props, ref) => {
    const forms = useForm<FormData>({});
    const { watch, handleSubmit, reset } = forms;

    useEffect(() => {
      reset({
        ...formConfig.reduce((acc, row) => {
          row.forEach((config) => {
            acc[config.id] = config.defaultValue ?? "";
          });
          return acc;
        }, {} as Record<string, string>),
      });
    }, [formConfig]);

    return (
      <FormProvider {...forms}>
        <form ref={ref} onSubmit={handleSubmit(onFormSubmit as any)}>
          {formConfig.map((row, i) => (
            <div className="flex gap-4" key={i}>
              {row.map((config) => (
                <FormRow
                  key={config.id}
                  {...config}
                  onBlur={() => onFormChange?.(watch() as FormData)}
                />
              ))}
            </div>
          ))}
        </form>
      </FormProvider>
    );
  }
);

export default SignatureForm;
