import emotionReset from "emotion-reset";
import styled from "@emotion/styled";

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 60px;
  padding: 0 1rem;
  font-size: calc(10px + 2vmin);
  background-color: #fff !important;
`;

export const Reset = styled.div`
  ${emotionReset}
`;
