// @ts-ignore
// prettier-ignore
import React from 'react';

import { ISignature } from "../interface/Signature";
import { copyHTML } from "../lib/clipboard";

import { Reset } from "../styles";

interface Props {
  data: ISignature;
  onCopy?: ($preview: HTMLDivElement) => void;
}

const SignaturePreview = ({ data, onCopy }: Props) => {
  const template = React.useMemo(
    () => `<table bgcolor="#f5f5f5" cellpadding="0" cellspacing="0" style="width: 100% !important; max-width: 600px !important; margin: 0 0 2px; padding: 0; border: 0 none" width="640">
  <tbody>
    <tr>
      <td bgcolor="#f5f5f5" style="width: 100%; margin: 0; padding: 20px; background-color: #f5f5f5; background: #f5f5f5; box-sizing: border-box">
        <table cellpadding="0" cellspacing="0" style="width: 100% !important; max-width: 560px !important; margin: 0; padding: 0; border: 0 none; vertical-align: top" width="560px">
          <tbody>
            <tr valign="center">
              <td align="left" style="vertical-align: top">
                <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; font-size: 14px; letter-spacing: -0.1px; color: #333; text-decoration: none; vertical-align: top; font-family: Arial, 'arial', Helvetica, Apple SD Gothic Neo, malgun gothic, 'malgun gothic', 'noto sans korean', 'noto sans korean regular', 'noto sans cjk kr', 'noto sans cjk', 'nanum gothic', dotum, sans-serif; word-break: keep-all; line-height: 20px; text-align: left"><strong style="font-size: 15px; font-weight: bold">${
                  data.name ? data.name : "(이름)"
                } ${
      data.nameEn ? data.nameEn : "(영문이름)"
    }</strong><br /><span style="font-size: 13px; font-weight: normal">${
      data.rank ? data.rank : "(직급)"
    }&nbsp;|&nbsp;${data.team ? data.team : "(부서)"}</span></div>
              </td>
              <td align="right">
                <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; text-align: right">
                  <span style="display: inline-block;height: 26.6px;vertical-align: top;padding-right:5px;margin-top: 6px;">
                    <a href="https://bit.ly/3TkMTLf" download target="_blank" >
                      <img src="https://mail-signature.gopizza.kr/email-assets/download-icon.png" width="40" height="26.67" alt="Download logo" style="display: inline-block;" />
                    </a>
                  </span>
                  <a href="https://bit.ly/3TGH8bd" target="_blank" style="text-decoration: none;">
                    <img src="https://mail-signature.gopizza.kr/email-assets/logo_gopizza_2024.png" width="208" height="37.48" alt="GOPIZZA" style="display: inline-block;"  />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="height: 15px; line-height: 15px"><br /></div>
        <div style="height: 15px; line-height: 15px; border-top-color: #b5b5b5; border-top-width: 1px; border-top-style: solid"><br /></div>
        <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; font-size: 14px; letter-spacing: -0.1px; color: #333; text-decoration: none; font-family: Arial, 'arial', Helvetica, Apple SD Gothic Neo, malgun gothic, 'malgun gothic', 'noto sans korean', 'noto sans korean regular', 'noto sans cjk kr', 'noto sans cjk', 'nanum gothic', dotum, sans-serif; word-break: keep-all; line-height: 20px; text-align: left">
          <strong style="font-size: 14px; font-weight: bold">e.</strong>&nbsp;<a href="mailto:${
            data.email
          }"><span style="font-style: normal !important; font-weight: normal !important">${
      data.email ? data.email : "(이메일)"
    }</span></a>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">m.</strong>&nbsp;<span style="font-size: 14px">${
      data.countryCode
    } ${
      data.tel ? data.tel : "(휴대폰 번호)"
    }</span>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">t.</strong>&nbsp;<span style="font-size: 14px">${
      data.countryCode
    } ${
      data.companyTel ? data.companyTel : "(회사 내선번호)"
    }</span>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">h.</strong>&nbsp;<a href="https://${data.homepageUrl?.replace(
      /(http|https):\/\//,
      ""
    )}" target="_blank"><span style="font-style: normal !important; font-weight: normal !important">${data.homepageUrl.replace(
      /(http|https):\/\//,
      ""
    )}</span></a>
    <br />
          <strong style="font-size: 14px; font-weight: bold">a.</strong>&nbsp;<span style="font-size: 14px">${
            data.address
          }</span>
        </div>
        <div style="height: 20px"><br /></div>
        <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both">
          <img src="https://mail-signature.gopizza.kr/email-assets/email-signature-text.png" width="560" height="112" alt="보안 경고 : 본 메일 및 첨부파일은 발신자 및 해당 수신자에게만\n공개된 내용이며, 기밀사항 혹은 법적으로 제한되거나\n저작권에 관련된 내용이 포함되어 있을 수 있습니다. 본메일을 무단으로 열람, 복사, 활용, 배포하는 행위는\n금지되어 있습니다. 만약 본 메일의 해당 수신자가 아니거나\n오류에 의해 전송 받았을 경우, 즉시 발신자에게 회신 후\n관련 메일을 모두 삭제해 주시기 바랍니다.\nCONFIDENTIALITY NOTICE : THIS E-MAIL, INCLUDING ANY ATTACHMENTS, IS A\nCONFIDENTIAL COMMUNICATION BETWEEN THE SENDER AND THE\nINTENDED RECIPIENT. IT MAY CONTAIN LEGALLY PRIVILEGED\nAND/OR COPYRIGHTED INFORMATION. YOU SHOULD NOT READ,\nCOPY, USE OR DISCLOSE THE CONTENT WITHOUT AUTHORIZATION\nIF YOU ARE NOT THE INTENDED RECIPIENT. IF YOU HAVE\nRECEIVED THIS MAIL IN ERROR, PLEASE CONTACT THE SENDER\nIMMEDIATELY BY RETURNING THIS E-MAIL AND THEN DELETE\nBOTH MESSAGES." />
        </div>
      </td>
    </tr>
  </tbody>
</table>`,
    [data]
  );

  const ref = React.useRef<HTMLDivElement>(null);

  const handleCopy = React.useCallback(() => {
    if (!ref?.current) return;
    onCopy?.(ref.current);
  }, [template]);

  return (
    <>
      <div className="flex my-2 text-sm justify-between items-center">
        <span>미리보기</span>
        <button
          type="button"
          className="text-white bg-[#171C8F] px-2 py-1 rounded"
          onClick={handleCopy}
        >
          코드 복사
        </button>
      </div>
      <Reset
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: template.replace(/\>(\s+)\</g, "><"),
        }}
      ></Reset>
    </>
  );
};

export default SignaturePreview;
