import { useCallback, useLayoutEffect, useRef } from "react";
import SignatureForm, { FormRow } from "../components/SignatureForm";
import SignaturePreview from "../components/SignaturePreview";
import React from "react";
import { ISignature } from "../interface/Signature";
import { copyText } from "../lib/clipboard";

const formConfig: FormRow[] = [
  [
    {
      id: "name",
      type: "text",
      label: "이름",
      // required: true,
    },
    {
      id: "nameEn",
      type: "text",
      label: "영문이름",
      // required: true,
    },
  ],
  [
    {
      id: "rank",
      type: "text",
      label: "직급",
      // required: true,
    },
    {
      id: "team",
      type: "text",
      label: "부서",
      // required: true,
    },
  ],
  [
    {
      id: "email",
      type: "email",
      label: "이메일",
      // required: true,
    },
  ],
  [
    {
      id: "countryCode",
      type: "select",
      label: "국가 코드",
      defaultValue: "+82",
      options: [{ label: "대한민국", value: "+82" }],
      required: true,
    },
    {
      id: "tel",
      type: "tel",
      label: "휴대폰 번호",
      placeholder: "10-0000-0000",
      // required: true,
    },
  ],
  [
    {
      id: "companyTel",
      type: "tel",
      label: "회사 내선번호",
      defaultValue: "2-567-8972",
      // required: true,
    },
  ],
  [
    {
      id: "address",
      type: "text",
      label: "주소",
      defaultValue:
        "15, Gyeonghuigung-gil, Jongno-gu, Seoul, Republic of Korea [03176]",
      // required: true,
    },
  ],
  [
    {
      id: "homepageUrl",
      type: "text",
      label: "홈페이지 주소",
      defaultValue: "www.gopizza.kr",
      // required: true,
    },
  ],
];

const Hq = function () {
  const formRef = React.useRef<HTMLFormElement>(null);
  const copyRef = React.useRef<HTMLDivElement>();

  const [watchFormData, setWatchFormData] = React.useState<ISignature>(
    formConfig.reduce((acc, row) => {
      row.forEach((config) => {
        acc[config.id as keyof ISignature] = config.defaultValue ?? "";
      });
      return acc;
    }, {} as ISignature)
  );

  useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", "ko");
  }, []);

  const handleFormChange = useCallback((data: any) => {
    setWatchFormData(data as any);
  }, []);

  const handleSubmit = useCallback((data: any) => {
    if (!copyRef.current) return;

    copyText(copyRef.current.innerHTML);
    alert("클립보드에 복사되었습니다.");
  }, []);

  return (
    <div>
      <SignatureForm
        ref={formRef}
        formConfig={formConfig}
        onFormChange={handleFormChange}
        onFormSubmit={handleSubmit}
      />
      <SignaturePreview
        data={watchFormData}
        onCopy={($preview) => {
          copyRef.current = $preview;
          formRef.current?.requestSubmit();
        }}
      />
    </div>
  );
};

export default Hq;
