import select from "./select";
import { createFakeElement } from "./fakeElement";
import { set } from "react-hook-form";

interface CopyActionOptions {
  container: HTMLElement;
}

const command = (action: "copy") => {
  try {
    return document.execCommand(action);
  } catch (e) {
    return false;
  }
};

export const copyHTML = (element: HTMLElement) => {
  const r = document.createRange();
  r.selectNode(element);
  window.getSelection()?.removeAllRanges();
  window.getSelection()?.addRange(r);
  command("copy");
  window.getSelection()?.removeAllRanges();
};

export const copyText = (
  text: string,
  { container }: CopyActionOptions = { container: document.body }
) => {
  const fakeElement = createFakeElement(text);
  container.appendChild(fakeElement);
  text = select(fakeElement);
  command("copy");
  container.removeChild(fakeElement);
};
