// @ts-ignore
// prettier-ignore
import React from 'react';

import { ISignature } from "../interface/Signature";

import { Reset } from "../styles";

interface Props {
  data: ISignature;
  onCopy?: ($preview: HTMLDivElement) => void;
}

const SignaturePreview = ({ data, onCopy }: Props) => {
  const template = React.useMemo(
    () => `<table bgcolor="#f5f5f5" cellpadding="0" cellspacing="0" style="width: 100% !important; max-width: 600px !important; margin: 0 0 2px; padding: 0; border: 0 none" width="640">
  <tbody>
    <tr>
      <td bgcolor="#f5f5f5" style="width: 100%; margin: 0; padding: 20px; background-color: #f5f5f5; background: #f5f5f5; box-sizing: border-box">
        <table cellpadding="0" cellspacing="0" style="width: 100% !important; max-width: 560px !important; margin: 0; padding: 0; border: 0 none; vertical-align: top" width="560px">
          <tbody>
            <tr valign="center">
              <td align="left" style="vertical-align: top">
                <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; font-size: 14px; letter-spacing: -0.1px; color: #333; text-decoration: none; vertical-align: top; font-family: Arial, 'arial', Helvetica, Apple SD Gothic Neo, malgun gothic, 'malgun gothic', 'noto sans korean', 'noto sans korean regular', 'noto sans cjk kr', 'noto sans cjk', 'nanum gothic', dotum, sans-serif; word-break: keep-all; line-height: 20px; text-align: left"><strong style="font-size: 15px; font-weight: bold">${
                  data.name ? data.name : "(Name)"
                }</strong><br /><span style="font-size: 13px; font-weight: normal">${
      data.rank ? data.rank : "(Job Title)"
    }&nbsp;|&nbsp;${data.team ? data.team : "(Department)"}</span></div>
              </td>
              <td align="right">
                <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; text-align: right">
                  <img src="https://mail-signature.gopizza.kr/email-assets/logo_gopizza_2024.png" crossorigin="anonymous" width="208" height="37.48" alt="GOPIZZA" style="display: inline-block;"  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="height: 15px; line-height: 15px"><br /></div>
        <div style="height: 15px; line-height: 15px; border-top-color: #b5b5b5; border-top-width: 1px; border-top-style: solid"><br /></div>
        <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both; font-size: 14px; letter-spacing: -0.1px; color: #333; text-decoration: none; font-family: Arial, 'arial', Helvetica, Apple SD Gothic Neo, malgun gothic, 'malgun gothic', 'noto sans korean', 'noto sans korean regular', 'noto sans cjk kr', 'noto sans cjk', 'nanum gothic', dotum, sans-serif; word-break: keep-all; line-height: 20px; text-align: left">
          <strong style="font-size: 14px; font-weight: bold">e.</strong>&nbsp;<a href="mailto:${
            data.email
          }"><span style="font-style: normal !important; font-weight: normal !important">${
      data.email ? data.email : "(email)"
    }</span></a>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">m.</strong>&nbsp;<span style="font-size: 14px">${
      data.countryCode
    } ${
      data.tel ? data.tel : "(Phone number)"
    }</span>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">t.</strong>&nbsp;<span style="font-size: 14px">${
      data.countryCode
    } ${
      data.companyTel ? data.companyTel : "(Company Tel. Number)"
    }</span>&nbsp;&nbsp;<strong style="font-size: 14px; font-weight: bold">h.</strong>&nbsp;<a href="https://${data.homepageUrl?.replace(
      /(http|https):\/\//,
      ""
    )}" target="_blank"><span style="font-style: normal !important; font-weight: normal !important">${
      data.homepageUrl
        ? data.homepageUrl.replace(/(http|https):\/\//, "")
        : "(Website)"
    }</span></a>
    <br />
          <strong style="font-size: 14px; font-weight: bold">a.</strong>&nbsp;<span style="font-size: 14px">${
            data.address ? data.address : "(Address)"
          }</span>
        </div>
        <div style="height: 20px;"><br /></div>
        <div style="overflow: hidden; width: 100%; min-width: 100%; padding: 0; margin: 0; clear: both">
          <img src="https://mail-signature.gopizza.kr/email-assets/email-signature-text-global.png" width="560" height="60" />
        </div>
      </td>
    </tr>
  </tbody>
</table>`,
    [data]
  );

  const ref = React.useRef<HTMLDivElement>(null);

  const handleCopy = React.useCallback(() => {
    if (!ref?.current) return;
    onCopy?.(ref.current);
  }, [template]);

  return (
    <>
      <div className="flex my-2 text-sm justify-between items-center">
        <span>PREVIEW</span>
        <button
          type="button"
          className="text-white bg-[#171C8F] px-2 py-1 rounded"
          onClick={handleCopy}
        >
          Copy code
        </button>
      </div>
      <Reset
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: template
            .replace(/\>(\s+)\</g, "><")
            .replace(/(;\s+)/g, ";")
            .replace(/(\n+)/g, ""),
        }}
      />
    </>
  );
};

export default SignaturePreview;
