import React from "react";

export const SignatureInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input">
>(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={`h-10 px-2 block rounded w-full border border-[lightgrey] ${className}`}
      {...props}
    />
  );
});

export const SignatureLabel = React.forwardRef<
  HTMLLabelElement,
  React.ComponentProps<"label">
>(({ className, ...props }, ref) => {
  return (
    <label ref={ref} className={`flex text-sm my-2 ${className}`} {...props}>
      {props.children}
    </label>
  );
});

export const SignatureSelect = React.forwardRef<
  HTMLSelectElement,
  React.ComponentProps<"select">
>(({ className, children, ...props }, ref) => {
  return (
    <select
      ref={ref}
      className={`h-10 px-1 w-full rounded border border-[lightgrey] text-sm ${className}`}
      {...props}
    >
      {children}
    </select>
  );
});

export const SignatureCheckbox = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & {
    label?: string;
    type: "radio" | "checkbox";
  }
>(({ label, checked, type, ...props }, ref) => (
  <label className={`inline-flex items-center`}>
    <input ref={ref} {...props} type={type} checked={checked} />
    <span className="pl-1 relative top-[-1px]">{label}</span>
  </label>
));
