export const createFakeElement = (text: string) => {
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";
  const fakeElement = document.createElement("textarea");
  // Prevent zooming on iOS
  fakeElement.style.fontSize = "12pt";
  // Reset box model
  fakeElement.style.border = "0";
  fakeElement.style.padding = "0";
  fakeElement.style.margin = "0";
  fakeElement.style.opacity = "0";
  // Move element out of screen horizontally
  fakeElement.style.position = "absolute";
  fakeElement.style[isRTL ? "right" : "left"] = "0";
  // Move element to the same position vertically
  let yPosition = window.pageYOffset || document.documentElement.scrollTop;
  fakeElement.style.top = `${yPosition}px`;

  fakeElement.setAttribute("readonly", "");
  fakeElement.value = text;

  return fakeElement;
};
