/**
 * @ref https://github.com/zenorocha/select/blob/master/src/select.js
 */

const getTextByElement = (element: HTMLElement): string => {
  if (element.hasAttribute("contenteditable")) {
    element.focus();
  }

  const selection = window?.getSelection();
  const range = document.createRange();

  if (!selection) return "";

  range.selectNodeContents(element);
  selection.removeAllRanges();
  selection.addRange(range);

  return selection.toString();
};

const getTextBySelect = (element: HTMLSelectElement): string => {
  element.focus();
  return element.value;
};

const getTextByInput = (element: HTMLInputElement): string => {
  const readonly = element.getAttribute("readonly");

  if (!readonly) {
    element.setAttribute("readonly", "");
  }

  element.select();
  element.setSelectionRange(0, element.value.length);

  if (!readonly) {
    element.removeAttribute("readonly");
  }

  return element.value;
};

export default (element?: HTMLElement) => {
  if (!element) return "";

  switch (true) {
    case element.nodeName === "SELECT":
      return getTextBySelect(element as HTMLSelectElement);
    case element?.nodeName === "INPUT":
    case element.nodeName === "TEXTAREA":
      return getTextByInput(element as HTMLInputElement);
    default:
      return getTextByElement(element);
  }
};
