import { useCallback, useLayoutEffect, useRef } from "react";
import SignatureForm, { FormRow } from "../components/SignatureForm";
import React from "react";
import { ISignature } from "../interface/Signature";
import SignaturePreviewGlobal from "../components/SignaturePreviewGlobal";

const formConfig: FormRow[] = [
  [
    {
      id: "name",
      type: "text",
      label: "Name",
      placeholder: "Name:",
      required: true,
    },
  ],
  [
    {
      id: "rank",
      type: "text",
      label: "Job Title",
      placeholder: "Job Title:",
      required: true,
    },
    {
      id: "team",
      type: "text",
      label: "Department",
      placeholder: "Department:",
      required: true,
    },
  ],
  [
    {
      id: "email",
      type: "email",
      label: "Email",
      placeholder: "Email address:",
      required: true,
    },
  ],
  [
    {
      id: "countryCode",
      type: "select",
      label: "Country Code",
      defaultValue: "",
      placeholder: "Please select country code",
      options: [
        // singapore, thailand, indonesia, india,
        { value: "+91", label: "India" },
        { value: "+65", label: "Singapore" },
        { value: "+62", label: "Indonesia" },
        { value: "+66", label: "Thailand" },
      ],
      required: true,
    },
    {
      id: "tel",
      type: "tel",
      label: "Phone number",
      placeholder: "Phone number:",
      required: true,
    },
  ],
  [
    {
      id: "companyTel",
      type: "tel",
      label: "Company Tel. Number",
      placeholder: "Company Tel. Number:",
      required: true,
    },
  ],
  [
    {
      id: "address",
      type: "text",
      label: "Address",
      placeholder: "Please enter your address here.",
      required: true,
    },
  ],
  [
    {
      id: "homepageUrl",
      type: "text",
      label: "Website",
      defaultValue: "",
      placeholder: "Please enter company website URL here.",
      required: true,
    },
  ],
];

const GlobalPage = function () {
  const formRef = React.useRef<HTMLFormElement>(null);
  const copyRef = React.useRef<HTMLDivElement>();

  const [watchFormData, setWatchFormData] = React.useState<ISignature>(
    formConfig.reduce((acc, row) => {
      row.forEach((config) => {
        acc[config.id as keyof ISignature] = config.defaultValue ?? "";
      });
      return acc;
    }, {} as ISignature)
  );

  useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", "en");
  }, []);

  const handleFormChange = useCallback((data: any) => {
    setWatchFormData(data as any);
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    if (!copyRef.current) return;

    // Clipboard API를 사용하여 HTML 코드를 클립보드로 복사합니다.
    const textBlob = new Blob([copyRef.current.innerHTML], {
      type: "text/html",
    });

    await navigator.clipboard.write([
      new ClipboardItem({ "text/html": textBlob }),
    ]);
    alert("Copied to your clipboard!");
  }, []);

  return (
    <div>
      <SignatureForm
        ref={formRef}
        formConfig={formConfig}
        onFormChange={handleFormChange}
        onFormSubmit={handleSubmit}
      />
      <SignaturePreviewGlobal
        data={watchFormData}
        onCopy={($preview) => {
          copyRef.current = $preview;
          formRef.current?.requestSubmit();
        }}
      />
    </div>
  );
};

export default GlobalPage;
